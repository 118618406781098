<template>
  <div class="light">
    <div class="l-header">
      <div>
        <router-link to="/SmartClassroom/AllEquipement"><i class="iconfont icon-control_arrow"></i></router-link>
      </div>
      <div>自动门</div>
      <div></div>
    </div>

    <div class="l-img">
      <div>
        <van-image v-if="turnState"
                   width="200"
                   :src='require("@/assets/自动门-正在打开中.gif")' />
        <van-image v-else
                   width="200"
                   :src='require("@/assets/自动门-正在关闭中.gif")' />
      </div>
    </div>
    <div class="l-state">{{ turnState ? "开启" : "关闭" }}</div>
    <div @click="turnStateFunc">
      <i :style="{
          color: turnState ? defineStyle.themeColor : defineStyle.color.defocus,
        }"
         class="iconfont icon-control_close"></i>
    </div>
  </div>
</template>
<script>
import { Image as VanImage, Slider } from "vant";
import defineMethods from "@/methods";
export default {
  components: {
    [VanImage.name]: VanImage,
    [Slider.name]: Slider,
  },
  data () {
    return {
      turnState: false,
      roomId: 0, //房间ID
      ServersCode: "",
    };
  },
  methods: {
    onChange () { },
    turnStateFunc () {
      if (this.turnState) {
        this.turnState = false;
        localStorage.LockState = false;
      } else {
        this.turnState = true;
        localStorage.LockState = true;
      }
      let obj = {
        ServersCode: this.ServersCode,
        Msg: this.turnState ? "2" : "0",
        Command: 5
      };
      console.log(obj);
      this.global.wsSendMsg('door', JSON.stringify(obj));
    },

  },
  computed: {},
  mounted () {
    this.roomId = this.$route.query.roomId;
    let terminalList = JSON.parse(defineMethods.getCookie("TerminalList"));
    this.ServersCode = terminalList[0].TerminalCode;

    if (!defineMethods.isNullOrEmpty(terminalList[0].SttributeJson)) {
      let jsonParse = JSON.parse(terminalList[0].SttributeJson);
      this.turnState = jsonParse.SWITCH == 'OPEN' ? true : false;

      if (!defineMethods.isNullOrEmpty(localStorage.LockState))
        this.turnState = localStorage.LockState == 'true' ? true : false;
    }

  },
};
</script>
<style lang="scss" scoped>
@import '../../../style/public.scss';
.light {
	width: 343px;
	margin: 0 auto;
	height: 457px;
	background: #fff;
	box-shadow: 0px 0px 10px #ebebeb;
	border-radius: 8px;
	.l-header {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		margin: 0 16px;
		padding: 16px 0;
		> div:first-child {
			color: $gray;
		}
		> div:nth-child(2) {
			font-size: 16px;
			font-weight: 400;
			color: $titleColor;
		}
	}
	.l-step {
		text-align: center;
		color: $gray;
		font-size: 20px;
		> div:first-child {
			width: 60%;
			margin: 0 auto;
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
		}
		> div:last-child {
			margin-top: 8px;
			font-size: 12px;
			font-weight: 400;
			color: $defocus;
		}
		.l-val {
			color: $themeColor;
			font-size: 24px;
			font-weight: 500;
		}
	}
	.l-img {
		width: 200px;
		height: 145px;
		margin: 0 auto;
		position: relative;
		margin-bottom: 15px;
		margin-top: 80px;
		.l-shell {
			position: absolute;
			top: 0;
			left: 0;
		}
		.l-shadow {
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	.l-state {
		margin: 20px 0;
		text-align: center;
		color: $defocus;
	}
	.l-state.active {
		color: $themeColor;
	}
	.l-slider {
		width: 75%;
		margin: 0 auto;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		color: $themeColor;
		> i {
			font-size: 20px;
		}
		> i:first-child {
			opacity: 0.3;
		}
		> div:nth-child(2) {
			width: 80%;
			margin: 0 5%;
		}
	}
	> div:last-child {
		width: 40px;
		height: 40px;
		margin: 0 auto;
		margin-top: 42px;
		> i {
			font-size: 40px;
			color: $themeColor;
		}
	}
}
</style>