<template>
  <div class="monitor">
    <div class="l-header">
      <div>
        <router-link to="/SmartClassroom/AllEquipement"><i class="iconfont icon-control_arrow"></i></router-link>
      </div>
      <div>室内检测仪</div>
      <div></div>
    </div>
    <div class="monitor-list">
      <van-grid :column-num="3"
                :gutter="15"
                :border="false">
        <van-grid-item icon="photo-o"
                       text="文字">
          <div class="list-item">
            <div>
              <i class="iconfont icon-control03_wendu"></i>
            </div>
            <div>{{ detail.TEM }}℃</div>
            <div>温度</div>
            <div v-if="18 <= detail.TEM && detail.TEM <= 25">正常</div>
            <div v-else
                 class="warnning">警告</div>
          </div>
        </van-grid-item>
        <van-grid-item icon="photo-o"
                       text="文字">
          <div class="list-item">
            <div>
              <i class="iconfont icon-control03_shidu"></i>
            </div>
            <div>{{ detail.HUM }}%rh</div>
            <div>湿度</div>
            <div v-if="30 <= detail.HUM && detail.HUM <= 80">正常</div>
            <div v-else
                 class="warnning">警告</div>
          </div>
        </van-grid-item>
        <van-grid-item icon="photo-o"
                       text="文字">
          <div class="list-item">
            <div>
              <i class="iconfont icon-control03_pm25"></i>
            </div>
            <div>{{ detail.TPF }}μg/m³</div>
            <div>PM2.5</div>
            <div v-if="detail.TPF < 50">正常</div>
            <div v-else
                 class="warnning">警告</div>
          </div>
        </van-grid-item>
        <van-grid-item icon="photo-o"
                       text="文字">
          <div class="list-item">
            <div>
              <i class="iconfont icon-control03_co2"></i>
            </div>
            <div>{{ detail.CO2 }}ppm</div>
            <div>二氧化碳</div>
            <div v-if="500 <= detail.CO2 && detail.CO2 <= 700">正常</div>
            <div v-else
                 class="warnning">警告</div>
          </div>
        </van-grid-item>
        <van-grid-item icon="photo-o"
                       text="文字">
          <div class="list-item">
            <div>
              <i class="iconfont icon-control03_jiaquan"></i>
            </div>
            <div>{{ detail.FMD }}mg/m³</div>
            <div>甲醛</div>
            <div v-if="detail.FMD < 0.08">正常</div>
            <div v-else
                 class="warnning">警告</div>
          </div>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>
<script>
import { Image as VanImage, Slider, Grid, GridItem } from "vant";
import defineMethods from "@/methods";
import { getRoomTester } from "@/api/TerminalEquipment/RoomEquipment.js";
export default {
  components: {
    [VanImage.name]: VanImage,
    [Slider.name]: Slider,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
  },
  data () {
    return {
      value: 24,
      active: [],
      roomId: 0, //房间ID
      detail: {}, //室内温度详情
    };
  },
  methods: {
    toggleState (i) {
      if (this.active[i] === i) {
        this.active.splice(i, 1);
      } else {
        this.$set(this.active, i, i);
      }
    },
    async roomTester () {
      let res = await getRoomTester(this.roomId);
      if (!defineMethods.isNullOrEmpty(res.Data.SttributeJson)) {
        this.detail = JSON.parse(res.Data.SttributeJson);
      }
    },
  },
  computed: {},
  mounted () {
    this.roomId = this.$route.query.roomId;
    this.roomTester();
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/public.scss";
.monitor {
  width: 343px;
  margin: 0 auto;
  height: 457px;
  background: #fff;
  box-shadow: 0px 0px 10px #ebebeb;
  border-radius: 8px;
  .list-item {
    width: 94px;
    height: 130px;
    background: $bgColor;
    text-align: center;
    border-radius: 5px;
    color: $titleColor;
    > div:first-child {
      margin-top: 16px;
      color: $titleColor;
      i {
        font-size: 26px;
        font-weight: 500;
      }
    }
    > div:nth-child(2),
    > div:nth-child(3),
    > div:nth-child(4) {
      margin-top: 6px;
    }
    > div:nth-child(2) {
      font-size: 14px;
    }
    > div:nth-child(3) {
      transform: scale(0.94);
    }
    > div:last-child {
      display: inline-block;
      padding: 3px 9px;
      border-radius: 10px;
      color: #fff;
      background: $green;
      font-size: 12px;
      transform: scale(0.84);
    }
    > div:last-child.warnning {
      padding: 1px 7px;
      background: $red;
    }
  }
  .l-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 16px;
    padding: 16px 0;
    margin-bottom: 52px;
    > div:first-child {
      color: $gray;
      // width: calc(50% - 30px);
    }
    > div:nth-child(2) {
      font-size: 16px;
      font-weight: 400;
      color: $titleColor;
      // flex: 1;
      // padding-left: 15px;
    }
  }
}
</style>
<style lang="scss">
.monitor {
  .van-grid-item__content {
    padding: 0;
  }
}
</style>