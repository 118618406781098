<template>
  <div class="light">
    <div class="l-header">
      <div>
        <router-link to="/SmartClassroom/AllEquipement">
          <i class="iconfont icon-control_arrow"
             @click="toScenario()"></i>
        </router-link>
      </div>
      <div>窗帘</div>
      <div></div>
    </div>
    <div class="l-step-horizontal">
      <div>
        <span @click="reduceHorizontal">
          <i class="iconfont icon-control_jian"></i></span>

        <span class="l-val"
              v-if="type === 0">{{ this.valueNum }}</span>
        <span class="l-val"
              v-if="type === 1">{{ 100 - value2 }}</span>

        <span @click="addHorizontal">
          <i class="iconfont icon-control_jia"></i></span>
      </div>
      <div>%</div>
    </div>
    <div class="l-slider-horizontal"
         v-if="type == 0">
      <div style="width: 200px">
        <span></span>
        <van-slider @input="inputHorizontalVal"
                    @change="inputHorizontalValChange"
                    range
                    :inactive-color="defineStyle.themeColorLight"
                    :active-color="defineStyle.color.gray"
                    bar-height="104px"
                    v-model="value1">
          <template #button>
            <div class="slider-btn">
              <i class="iconfont icon-fangjian_arrow"></i>
              <i class="iconfont icon-fangjian_arrow"></i>
            </div>
          </template>
        </van-slider>
      </div>
    </div>

    <div class="l-slider-vertical"
         v-if="type == 1">
      <div>
        <span style="width: 134px"></span>
        <van-slider @change="inputVerticalVal"
                    vertical
                    :inactive-color="defineStyle.color.gray"
                    :active-color="defineStyle.themeColorLight"
                    bar-height="134px"
                    v-model="value2">
          <template #button>
            <div class="slider-btn">
              <i class="iconfont icon-fangjian_arrow"></i>
              <i class="iconfont icon-fangjian_arrow"></i>
            </div>
          </template>
        </van-slider>
      </div>
    </div>

    <div class="l-func">
      <div @click="toggleState(0)"
           :class="terLocationIndex == 0 ? 'active' : ''">
        <span>全部</span>
      </div>
      <div @click="toggleState(1)"
           :class="terLocationIndex == 1 ? 'active' : ''">
        <span>前一</span>
      </div>
      <div @click="toggleState(2)"
           :class="terLocationIndex == 2 ? 'active' : ''">
        <span>前二</span>
      </div>
      <div @click="toggleState(3)"
           :class="terLocationIndex == 3 ? 'active' : ''">
        <span>后一</span>
      </div>
      <div @click="toggleState(4)"
           :class="terLocationIndex == 4 ? 'active' : ''">
        <span>后二</span>
      </div>
    </div>
    <div @click="changeState">
      <i :style="{
          color: valueNum > 0 ? defineStyle.themeColor : defineStyle.color.defocus,
        }"
         class="iconfont icon-control_close"></i>
    </div>
  </div>
</template>
<script>
import { Slider, Toast } from "vant";
import defineMethods from "@/methods";
export default {
  components: {
    [Slider.name]: Slider,
  },
  data () {
    return {
      type: 0, // 0左右开 1上下开
      value1: [50, 50],
      value2: 0,
      active: [0],
      direction: "horizontal",
      chartPie: null,
      tIds: "", //设备ids
      tCodeArr: [], //设备arr
      gatewayCode: "", //网关code
      gatewayIp: "", //网关IP
      terminalCmd: [], //指令
      timeSendCmdFirst: null,
      nowSettingType: -1,// 0- 1+
      settingSecond: 10,//执行总操作秒速
      jieSuoSecond: 0,
      timeImplement: null,
      timeId: null,
      valueNum: 100,
      oldNum: 0,
      terList: [],
      terLocationIndex: 0,
      terValueArr: []
    };
  },
  methods: {
    //切换标签
    toggleState (i) {
      if (i > this.terList.length) {
        this.$toast("该位置无设备");
        return false;
      }
      this.terLocationIndex = i;
      if (this.terValueArr[i] == null) {
        this.valueNum = 100;
        this.value2 = 100 - this.valueNum;
      } else {
        this.valueNum = this.terValueArr[i];
        this.value2 = 100 - this.valueNum;
      }
    },
    //点击按钮
    changeState () {
      if (this.valueNum > 0) {
        this.calculationValue(0, 0);
      } else {
        this.calculationValue(100, 1);
      }
    },
    // - 
    reduceHorizontal () {
      if (this.type == 1) {
        this.$toast("操作不支持");
        this.value2 = 100 - this.oldNum;
        return false;
      }

      //当前正在执行加法,不可操作减法
      if (this.nowSettingType == 1)
        return false;
      let num = parseInt(this.valueNum) - 10;
      if (num >= 0)
        this.calculationValueSaveList(num, 0);
    },
    // + 
    addHorizontal () {
      if (this.type == 1) {
        this.$toast("操作不支持");
        this.value2 = 100 - this.oldNum;
        return false;
      }

      //当前正在执行减法,不可操作减法
      if (this.nowSettingType == 0)
        return false;
      let num = parseInt(this.valueNum) + 10;
      if (num <= 100)
        this.calculationValueSaveList(num, 1);
    },
    //执行list保存得指令
    implementListCmd: function () {
      if (this.timeSendCmdFirst == null)
        return false;

      let jieSuo = 0;//解锁秒速
      if (this.timeSendCmdFirst.levType == 1) {
        let cha = this.timeSendCmdFirst.num - this.oldNum;
        this.nowSettingType = this.timeSendCmdFirst.levType;
        jieSuo = parseFloat(cha / 100 * this.settingSecond);
      } else {
        let cha = this.oldNum - this.timeSendCmdFirst.num;
        this.nowSettingType = this.timeSendCmdFirst.levType;
        jieSuo = parseFloat(cha / 100 * this.settingSecond);
      }
      if (this.jieSuoSecond < jieSuo) {
        if (this.setOutTime != null)
          clearInterval(this.setOutTime);

        this.setOutTime = setTimeout(() => {
          this.nowSettingType = -1;
          this.jieSuoSecond = 0;
          console.log("可以点了", jieSuo);
        }, jieSuo * 1000);
      }

      this.jieSuoSecond = jieSuo;
      this.sendCmd(this.timeSendCmdFirst.levType);
      this.oldNum = this.timeSendCmdFirst.num;
      this.timeSendCmdFirst = null;
    },
    //计算窗帘值
    calculationValueSaveList (num, levType) {
      this.okTime();
      this.nowSettingType = levType;
      this.valueNum = num;
      if (this.type == 0) {
        //左右
        let proportionNum = parseInt(num / 100 * 50);
        this.value1[0] = 50 - proportionNum;
        this.value1[1] = 50 + proportionNum;
        this.$set(this.value1, 0, 50 - proportionNum);
        this.$set(this.value1, 1, 50 + proportionNum);
      } else {
        this.value2 = 100 - num;
      }
      this.timeSendCmdFirst = { levType, num };
    },
    //左右,input
    inputHorizontalVal (val) {
      //限制期间,无法拖动
      if (this.nowSettingType != -1) {
        let proportionNum = parseInt(this.oldNum / 100 * 50);
        this.value1[0] = 50 - proportionNum;
        this.value1[1] = 50 + proportionNum;
        this.$set(this.value1, 0, 50 - proportionNum);
        this.$set(this.value1, 1, 50 + proportionNum);
        return false;
      }
      if (val[1] <= 50) {
        this.valueNum = 0;
        this.$set(this.value1, 0, 50);
        this.$set(this.value1, 1, 50);
        return false;
      }
      let num = (val[1] - 50) * 2;
      this.valueNum = num;
      let proportionNum = parseInt(num / 100 * 50);
      this.value1[0] = 50 - proportionNum;
      this.value1[1] = 50 + proportionNum;
      this.$set(this.value1, 0, 50 - proportionNum);
      this.$set(this.value1, 1, 50 + proportionNum);
    },
    //左右,change
    inputHorizontalValChange (val) {

      if (this.nowSettingType != -1) {
        let proportionNum = parseInt(this.oldNum / 100 * 50);
        this.value1[0] = 50 - proportionNum;
        this.value1[1] = 50 + proportionNum;
        this.$set(this.value1, 0, 50 - proportionNum);
        this.$set(this.value1, 1, 50 + proportionNum);
        return false;
      }

      let num = (val[1] - 50) * 2;
      if (this.oldNum > num) {
        this.calculationValue(num, 0);
      }
      else {
        this.calculationValue(num, 1);
      }
    },
    //上下
    inputVerticalVal (val) {
      if (this.nowSettingType != -1)
        return false;
      if (this.type == 1) {
        this.$toast("操作不支持");
        this.value2 = 100 - this.oldNum;
        return false;
      }

      let num = 100 - val;
      if (this.oldNum > num)
        this.calculationValue(num, 0);
      else
        this.calculationValue(num, 1);
    },
    //发送指令
    sendCmd (levType) {
      let cmdJson = null;
      if (this.valueNum == 0) {
        cmdJson = defineMethods.getCmdJson(3, 1);
      } else {
        cmdJson = defineMethods.getCmdJson(3, 2);
      }
      localStorage.CurtainsNum = this.valueNum;
      defineMethods.openTerminalCmd(cmdJson, 3, this.gatewayCode, this.tCodeArr[0],
        this.valueNum, levType);
    },
    //发送指令
    sendCmd_7 (lev) {
      let cmdJson = defineMethods.getCmdJson(7, 1);
      if (this.terLocationIndex == 0) {
        this.$set(this.terValueArr, 0, lev);
        this.$set(this.terValueArr, 1, lev);
        this.$set(this.terValueArr, 2, lev);
        this.$set(this.terValueArr, 3, lev);
        this.$set(this.terValueArr, 4, lev);
        this.terList.forEach((q, index) => {
          if (!defineMethods.isNullOrEmpty(q.SttributeJson)) {
            let jsonSttributeJson = JSON.parse(q.SttributeJson);
            if (index == 0) {
              defineMethods.openTerminalCmd(cmdJson, 7, q.GatewayCode, '00124B0021269DEA',
                lev > 0 ? jsonSttributeJson.OPEN : jsonSttributeJson.COLSE);
            } else {
              setTimeout(() => {
                defineMethods.openTerminalCmd(cmdJson, 7, q.GatewayCode, '00124B0021269DEA',
                  lev > 0 ? jsonSttributeJson.OPEN : jsonSttributeJson.COLSE);
              }, index * 1300);
            }
          }
        });
      } else {
        this.$set(this.terValueArr, this.terLocationIndex, lev);
        let q = this.getTerFirst("#" + this.terLocationIndex);
        if (!defineMethods.isNullOrEmpty(q.SttributeJson)) {
          let jsonSttributeJson = JSON.parse(q.SttributeJson);
          defineMethods.openTerminalCmd(cmdJson, 7, q.GatewayCode, '00124B0021269DEA',
            lev > 0 ? jsonSttributeJson.OPEN : jsonSttributeJson.COLSE);
        }
      }
      localStorage.CurtainsNum = lev > 0 ? 100 : 0;
    },
    //计算窗帘值
    calculationValue (num, levType) {
      this.valueNum = num;
      if (this.type == 0) {
        //左右
        let proportionNum = parseInt(num / 100 * 50);
        this.value1[0] = 50 - proportionNum;
        this.value1[1] = 50 + proportionNum;
        this.$set(this.value1, 0, 50 - proportionNum);
        this.$set(this.value1, 1, 50 + proportionNum);
      } else {
        this.value2 = 100 - num;
      }
      //发送指令
      if (this.tIds != '' && this.type == 0)
        this.sendCmd(levType);
      else if (this.type == 1)
        this.sendCmd_7(num);

      this.oldNum = num;
    },
    toScenario () {
      clearInterval(this.timeImplement);
      //前往房间场景
      this.$router.push({
        path: "/SmartClassroom/AllEquipement",
        query: {
          roId: this.roomId,
        },
      });
    },
    okTime () {
      if (this.timeImplement == null) {
        this.timeImplement = setInterval(() => {
          this.implementListCmd();
        }, 1500);
      }
    },
    //获取设备信息
    getTerFirst (location) {
      let info = null;
      this.terList.forEach((a) => {
        if (a.Location == location) {
          info = a;
        }
      });
      return info;
    },
  },
  computed: {},
  beforeDestroy () {    //页面关闭时清除定时器  
    clearInterval(this.timeImplement);
  },
  mounted () {
    this.roomId = this.$route.query.roomId;
    this.type = this.$route.query.terType == 3 ? 0 : 1;
    let terminalList = JSON.parse(defineMethods.getCookie("TerminalList"));

    if (this.type == 0) {
      if (!defineMethods.isNullOrEmpty(terminalList[0].SttributeJson)) {
        let jsonParse = JSON.parse(terminalList[0].SttributeJson);
        let num = jsonParse.WIN == 'OPEN' ? jsonParse.LEV : 0;
        if (!defineMethods.isNullOrEmpty(localStorage.CurtainsNum))
          num = parseInt(localStorage.CurtainsNum);
        this.calculationValue(num, 1);
      }
      let ids = "",
        codeArr = [];
      terminalList.forEach((a) => {
        ids += "," + a.id;
        codeArr.push(a.TerminalCode);
      });
      this.tIds = ids.substr(1);
      this.tCodeArr = codeArr;
      this.gatewayCode = terminalList[0].GatewayCode;
    } else {
      this.terList = terminalList;
    }
  },
};
</script>
<style lang="scss" scoped>
@import '../../../style/public.scss';
.light {
	width: 343px;
	margin: 0 auto;
	height: 457px;
	background: #fff;
	box-shadow: 0px 0px 10px #ebebeb;
	border-radius: 8px;
	.l-header {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		margin: 0 16px;
		padding: 16px 0;
		> div:first-child {
			color: $gray;
		}
		> div:nth-child(2) {
			font-size: 16px;
			font-weight: 400;
			color: $titleColor;
			margin-left: -15px;
		}
	}
	.l-func {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-self: center;
		> div {
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: center;
			color: $gray;
			padding: 4px 6px;
			font-size: 14px;
			font-weight: 400;
			margin-top: 24px;
		}
		> div.active {
			color: $themeColor;
			background: themeRGBColor(0.1);
			border-radius: 8px;
			i {
				color: $themeColor;
			}
		}
	}
	.l-step-horizontal {
		text-align: center;
		color: $gray;
		font-size: 20px;
		> div:first-child {
			width: 60%;
			margin: 0 auto;
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
		}
		> div:last-child {
			margin-top: 8px;
			font-size: 12px;
			font-weight: 400;
			color: $defocus;
		}
		.l-val {
			color: $themeColor;
			font-size: 24px;
			font-weight: 500;
		}
	}
	.l-slider-horizontal {
		width: 80%;
		height: 180px;
		margin: 0 auto;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		color: $themeColor;
		.slider-btn {
			width: 26px;
			height: 26px;
			background: #ffffff;
			box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
			border-radius: 50%;
			position: relative;
			> i {
				font-size: 12px;
				color: #7f7f7f;
			}
			> i:first-child {
				display: inline-block;
				transform: rotateZ(90deg) translateX(50%) translateY(90%) scale(0.6);
				position: absolute;
				left: 50%;
				top: 0;
			}
			> i:last-child {
				display: inline-block;
				transform: rotateZ(-90deg) translateX(50%) translateY(5%) scale(0.6);
				position: absolute;
				left: 50%;
				bottom: 0;
			}
		}
		> div {
			margin: 0 auto;
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: center;
			> span {
				display: inline-block;
				width: 100%;
				height: 20px;
				background: $themeColor;
				border-radius: 8px 8px 0 0;
			}
		}
		.van-slider {
			height: 160px;
			border-radius: 0 0 8px 8px;
			.van-slider__bar {
				border-radius: 0;
			}
		}
	}
	.l-slider-vertical {
		width: 80%;
		height: 180px;
		margin: 0 auto;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		color: $themeColor;
		.slider-btn {
			width: 26px;
			height: 26px;
			background: #ffffff;
			box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
			border-radius: 50%;
			position: relative;
			> i {
				font-size: 12px;
				color: #7f7f7f;
			}
			> i:first-child {
				display: inline-block;
				transform: rotateZ(180deg) translateX(50%) scale(0.6);
				position: absolute;
				left: 50%;
				top: 0;
			}
			> i:last-child {
				display: inline-block;
				transform: rotateZ(0deg) translateX(-50%) scale(0.6);
				position: absolute;
				left: 50%;
				bottom: 0;
			}
		}
		> div {
			width: 200px;
			margin: 0 auto;
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: center;
			> span {
				display: inline-block;
				height: 20px;
				background: $themeColor;
				border-radius: 8px 8px 0 0;
			}
		}
		.van-slider {
			height: 160px;
			border-radius: 0 0 8px 8px;
			.van-slider__bar {
				border-radius: 0;
			}
		}
	}
	.l-img {
		width: 110px;
		height: 180px;
		margin: 0 auto;
		position: relative;
		margin-bottom: 15px;
		.l-shell {
			position: absolute;
			top: 0;
			left: 0;
		}
		.l-shadow {
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	> div:last-child {
		width: 40px;
		height: 40px;
		margin: 0 auto;
		margin-top: 42px;
		> i {
			font-size: 40px;
			color: $themeColor;
		}
	}
}
</style>
<style lang="scss">
.light {
	.l-slider-horizontal {
		.van-slider {
			height: 160px;
			border-radius: 8px;
			overflow: hidden;
			.van-slider__bar {
				border-radius: 0;
			}
		}
	}
}
</style>