//房间设备接口

import service from "../config.js";
import link from "../link.js";

let ApiUrl = link.IOtApiChen.APIService;

//获取默认房间信息
export const getDefaultRoom = async () => {
  let responseData = await service({
    url: ApiUrl + "/api/RoomEquipment/getDefaultRoom",
    method: "get"
  })
  return responseData;
}

//获取房间测试仪信息
export const getRoomTester = async (rid) => {
  let responseData = await service({
    url: ApiUrl + "/api/RoomEquipment/GetRoomTester",
    method: "get",
    params: {
      roomId: rid
    }
  })
  return responseData;
}

//获取房间下所有设备
export const getRoomEquipmentAll = async (rid) => {
  let responseData = await service({
    url: ApiUrl + "/api/RoomEquipment/GetRoomEquipmentAll",
    method: "get",
    params: {
      roomId: rid
    }
  })
  return responseData;
}

//获取房间内指定类型设备
export const getRoomTypeEquipment = async (rid, tType) => {
  let responseData = await service({
    url: ApiUrl + "/api/RoomEquipment/GetRoomTypeEquipment",
    method: "get",
    params: {
      roomId: rid,
      terminalType: tType
    }
  })
  return responseData;
}

//获取房间内所有场景
export const getScenarioAll = async (rid) => {
  let responseData = await service({
    url: ApiUrl + "/api/RoomEquipment/GetScenarioAll",
    method: "get",
    params: {
      roomId: rid
    }
  })
  return responseData;
}

//获取场景设备参数详情
export const getScenarioDetail = async (sid, typeStr) => {
  let responseData = await service({
    url: ApiUrl + "/api/RoomEquipment/GetScenarioDetail",
    method: "get",
    params: {
      scenarioId: sid,
      terminalTypes: typeStr
    }
  })
  return responseData;
}

//设置设备参数值
export const settingEquipmentValue = async (tIds, sJson) => {
  let responseData = await service({
    url: ApiUrl + "/api/RoomEquipment/SettingEquipmentValue",
    method: "post",
    data: {
      terminaIds: tIds,
      sttributeJson: sJson
    }
  })
  return responseData;
}