<template>
  <div class="radio">
    <div class="l-header">
      <div>
        <router-link to="/SmartClassroom/AllEquipement">
          <i class="iconfont icon-control_arrow"></i
        ></router-link>
      </div>
      <div>广播</div>
      <div></div>
    </div>
    <div @touchstart.prevent="readyToPlay" @touchend.prevent="endToPlay">
      <van-image width="240px" height="240px" :src="img" />
    </div>
    <div>{{ value }}%</div>
    <div>
      <i class="iconfont icon-control02_yinliang"></i>
      <div>
        <van-slider
          :active-color="defineStyle.themeColor"
          bar-height="6px"
          v-model="value"
        ></van-slider>
      </div>
      <i class="iconfont icon-control02_yinliang"></i>
    </div>
  </div>
</template>
<script>
import defineMethods from "@/methods";
import { Image as VanImage, Slider } from "vant";
export default {
  components: {
    [VanImage.name]: VanImage,
    [Slider.name]: Slider,
  },
  data() {
    return {
      roomId: 0, //房间ID
      terminaList: [], //设备集合
      value: 80,
      img: require("@/assets/广播按钮-静止.png"),
    };
  },
  methods: {
    readyToPlay() {
      setTimeout(() => {
        this.img = require("@/assets/广播按钮-播放.gif");
      }, 500);
    },
    endToPlay() {
      this.img = require("@/assets/广播按钮-静止.png");
    },
  },
  computed: {},
  mounted() {
    this.roomId = this.$route.query.roomId;
    this.terminaList = JSON.parse(defineMethods.getCookie("TerminalList"));
    console.log(this.roomId);
    console.log(this.terminaList[0].TerminalName);
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/public.scss";
.radio {
  width: 343px;
  margin: 0 auto;
  height: 457px;
  background: #fff;
  box-shadow: 0px 0px 10px #ebebeb;
  border-radius: 8px;
  .l-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 16px;
    padding: 16px 0;
    > div:first-child {
      color: $gray;
    }
    > div:nth-child(2) {
      font-size: 16px;
      font-weight: 400;
      color: $titleColor;
      margin-left: -15px;
    }
  }
  > div:nth-child(2) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
  > div:nth-child(3) {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 27px;
    font-size: 16px;
    font-weight: 400;
    color: $titleColor;
  }
  > div:nth-child(4) {
    > div {
      width: 200px;
    }
    > i:first-child {
      margin-right: 8px;
      color: $themeColor;
      opacity: 0.4;
    }
    > i:last-child {
      margin-left: 8px;
      color: $themeColor;
    }
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
}
</style>