<template>
  <div class="light">
    <div class="l-header">
      <div>
        <i class="iconfont icon-control_arrow"
           @click="toScenario()"></i>
      </div>
      <div>智能照明灯</div>
      <div></div>
    </div>
    <div class="l-step">
      <div>
        <span @click="reduceFunc">
          <i class="iconfont icon-control_jian"></i></span>
        <span class="l-val">{{ value }}</span>
        <span @click="plusFunc">
          <i class="iconfont icon-control_jia"></i></span>
      </div>
      <div>%</div>
    </div>
    <div class="l-img">
      <div>
        <van-image class="l-shadow"
                   width="175"
                   :style="{ opacity: value / 100 }"
                   :src="require('@/assets/light-shadow.png')" />
        <van-image class="l-shell"
                   width="175"
                   :src="require('@/assets/light-shell.png')" />
      </div>
    </div>
    <div class="l-slider">
      <i class="iconfont icon-control02_sun"></i>
      <div>
        <van-slider :active-color="defineStyle.themeColor"
                    bar-height="6px"
                    v-model="value"
                    @change="sendCmd"></van-slider>
      </div>
      <i class="iconfont icon-control02_sun"></i>
    </div>
    <div @click="onClick">
      <i :style="{
          color: turnState ? defineStyle.themeColor : defineStyle.color.defocus,
        }"
         class="iconfont icon-control_close"></i>
    </div>
  </div>
</template>
<script>
import { Image as VanImage, Slider, Toast } from "vant";
import defineMethods from "@/methods";

import { settingEquipmentValue } from "@/api/TerminalEquipment/RoomEquipment.js";

export default {
  components: {
    [VanImage.name]: VanImage,
    [Slider.name]: Slider,
  },
  props: {
    yourData: {
      type: Object,
    },
  },
  data () {
    return {
      websock: null,
      turnState: false,
      value: 80,
      roomId: 0, //房间ID
      tIds: "", //设备ids
      tCodeArr: [], //设备arr
      gatewayCode: "", //网关code
      gatewayIp: "", //网关IP
      terminalCmd: [], //指令
      timeId: null,
    };
  },
  methods: {
    // -
    reduceFunc () {
      if (this.value != 0) {
        this.value = this.value - 10;
        this.sendCmd();
      }
    },
    //+
    plusFunc () {
      if (this.value != 100) {
        this.value = this.value + 10;
        this.sendCmd();
      }
    },
    //按钮点击
    onClick () {
      if (this.value > 0) {
        this.value = 0; //关闭
      } else {
        this.value = 100; //打开
      }
      this.sendCmd();
    },
    sendCmd () {
      let cmdJson = null;
      if (this.value == 0) {
        cmdJson = defineMethods.getCmdJson(1, 1);
        this.turnState = false;
      } else {
        cmdJson = defineMethods.getCmdJson(1, 2);
        this.turnState = true;
      }
      localStorage.LightNum = this.value;
      defineMethods.openTerminalCmd(
        cmdJson,
        1,
        this.gatewayCode,
        this.tCodeArr[0],
        this.value
      );
    },
    toScenario () {
      //前往房间场景
      this.$router.push({
        path: "/SmartClassroom/AllEquipement",
        query: {
          roId: this.roomId,
        },
      });
    },
  },
  computed: {},
  mounted () {
    this.roomId = this.$route.query.roomId;

    let terminalList = JSON.parse(defineMethods.getCookie("TerminalList"));

    if (!defineMethods.isNullOrEmpty(terminalList[0].SttributeJson)) {
      let jsonParse = JSON.parse(terminalList[0].SttributeJson);
      this.value = jsonParse.SWI == "ON" ? parseInt(jsonParse.LEV) : 0;
      if (!defineMethods.isNullOrEmpty(localStorage.LightNum))
        this.value = parseInt(localStorage.LightNum);
    }

    let ids = "",
      codeArr = [];
    terminalList.forEach((a) => {
      ids += "," + a.id;
      codeArr.push(a.TerminalCode);
    });
    this.tIds = ids.substr(1);
    this.tCodeArr = codeArr;
    this.gatewayCode = terminalList[0].GatewayCode;
    this.turnState = this.value > 0 ? true : false;
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/public.scss";
.light {
  width: 343px;
  margin: 0 auto;
  height: 457px;
  background: #fff;
  box-shadow: 0px 0px 10px #ebebeb;
  border-radius: 8px;
  .l-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 16px;
    padding: 16px 0;
    > div:first-child {
      color: $gray;
    }
    > div:nth-child(2) {
      font-size: 16px;
      font-weight: 400;
      color: $titleColor;
    }
  }
  .l-step {
    text-align: center;
    color: $gray;
    font-size: 20px;
    > div:first-child {
      width: 60%;
      margin: 0 auto;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
    }
    > div:last-child {
      margin-top: 8px;
      font-size: 12px;
      font-weight: 400;
      color: $defocus;
    }
    .l-val {
      color: $themeColor;
      font-size: 24px;
      font-weight: 500;
    }
  }
  .l-img {
    width: 175px;
    height: 190px;
    margin: 0 auto;
    position: relative;
    margin-bottom: 15px;
    .l-shell {
      position: absolute;
      top: 0;
      left: 0;
    }
    .l-shadow {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .l-slider {
    width: 75%;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    color: $themeColor;
    > i {
      font-size: 20px;
    }
    > i:first-child {
      opacity: 0.3;
    }
    > div:nth-child(2) {
      width: 80%;
      margin: 0 5%;
    }
  }
  > div:last-child {
    width: 40px;
    height: 40px;
    margin: 0 auto;
    margin-top: 42px;
    > i {
      font-size: 40px;
      color: $themeColor;
    }
  }
}
</style>