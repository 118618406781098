<template>
  <div class="light">
    <div class="l-header">
      <div>
        <router-link to="/SmartClassroom/AllEquipement"
          ><i class="iconfont icon-control_arrow"></i
        ></router-link>
      </div>
      <div>空调</div>
      <div></div>
    </div>
    <!-- <div class="l-subtitle">房间温度26℃</div> -->
    <div class="l-temperature">
      <div id="chartPie"></div>

      <div>
        <div>制冷</div>
        <div>
          <i @click="reduceData" class="iconfont icon-control_jian"></i>

          <span>
            <i>{{ value }}</i>
            <i>℃</i>
          </span>

          <i @click="addData" class="iconfont icon-control_jia"></i>
        </div>
      </div>
    </div>
    <div class="l-func">
      <div @click="toggleState(0)" :class="active.includes(0) ? 'active' : ''">
        <i class="iconfont icon-control02_zhileng"></i>
        <span>制冷</span>
      </div>
      <div @click="toggleState(1)" :class="active.includes(1) ? 'active' : ''">
        <i class="iconfont icon-control02_nuanqi"></i>
        <span>暖气</span>
      </div>
      <div @click="toggleState(2)" :class="active.includes(2) ? 'active' : ''">
        <i class="iconfont icon-control02_songfeng"></i>
        <span>送风</span>
      </div>
      <div @click="toggleState(3)" :class="active.includes(3) ? 'active' : ''">
        <i class="iconfont icon-control02_chushi"></i>
        <span>除湿</span>
      </div>
    </div>
    <div @click="changeState">
      <i
        class="iconfont icon-control_close"
        :class="{ active: value != 0 }"
      ></i>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
require("echarts/theme/macarons"); //引入主题
import { Image as VanImage, Slider } from "vant";
import defineMethods from "@/methods";
export default {
  components: {
    [VanImage.name]: VanImage,
    [Slider.name]: Slider,
  },
  data() {
    return {
      value: 24,
      active: [],
      chartPie: null,
      roomId: 0, //房间ID
      terminaList: [], //设备集合
    };
  },
  methods: {
    changeState() {
      this.value > 0 ? (this.value = 0) : (this.value = 100);
      this.drawPieChart();
    },
    reduceData() {
      this.value <= 0 ? (this.value = 0) : this.value--;
      this.drawPieChart();
    },
    addData() {
      this.value >= 100 ? (this.value = 100) : this.value++;
      this.drawPieChart();
    },
    toggleState(i) {
      if (this.active[i] === i) {
        this.active.splice(i, 1);
      } else {
        this.$set(this.active, i, i);
      }
    },
    drawPieChart() {
      this.chartPie = echarts.init(
        document.getElementById("chartPie"),
        "macarons"
      );
      this.chartPie.setOption({
        tooltip: {
          formatter: "{b}{c}",
        },
        series: [
          {
            name: "本期",
            type: "gauge",
            radius: "88%",
            min: 0,
            max: 100,
            center: ["50%", "50%"],
            splitNumber: 7, //刻度数量
            z: 8,
            startAngle: 208,
            endAngle: -30,
            axisLine: {
              show: true,
              lineStyle: {
                width: 0,
                color: [[1, this.defineStyle.color.gray]],
              },
            }, //仪表盘轴线
            axisLabel: {
              show: false,
            },
            axisTick: {
              splitNumber: 7,
              show: true,
              lineStyle: {
                color: "auto",
                width: 2,
              },
              length: 3,
            }, //刻度样式
            splitLine: {
              show: false,
              length: 5,
              lineStyle: {
                color: "auto",
                width: 5,
              },
            },
          },
          {
            name: "本期",
            type: "gauge",
            radius: "108%",
            min: 0,
            max: 100,
            center: ["50%", "50%"],
            splitNumber: 7, //刻度数量
            z: 6,
            startAngle: 208,
            endAngle: -30,
            axisLine: {
              show: true,
              lineStyle: {
                width: 0,
                shadowBlur: 0,
                color: [
                  [this.value / 100, this.defineStyle.themeColor],
                  [1, "#ebebeb"],
                ],
              },
            }, //仪表盘轴线
            axisLabel: {
              show: false,
            },
            axisTick: {
              splitNumber: 7,
              show: true,
              lineStyle: {
                color: "auto",
                width: 2,
              },
              length: 20,
            }, //刻度样式
            splitLine: {
              show: false,
              length: 20,
              lineStyle: {
                color: "auto",
                width: 5,
              },
            },
          },
        ],
      });
    },
  },
  computed: {},
  mounted() {
    this.$nextTick(() => {
      this.drawPieChart();
    });

    this.roomId = this.$route.query.roomId;
    this.terminaList = JSON.parse(defineMethods.getCookie("TerminalList"));
    console.log(this.roomId);
    console.log(this.terminaList[0].TerminalName);
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/public.scss";
.light {
  width: 343px;
  margin: 0 auto;
  height: 457px;
  background: #fff;
  box-shadow: 0px 0px 10px #ebebeb;
  border-radius: 8px;
  .l-subtitle {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: $gray;
  }
  .l-temperature {
    width: 100%;
    height: 260px;
    position: relative;
    margin: 0 auto;
    > div:first-child {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 10%;
      left: 0;
    }
    > div:last-child {
      position: absolute;
      bottom: 30%;
      left: 50%;
      width: 160px;
      text-align: center;
      transform: translateX(-50%);
      color: $gray;
      font-size: 14px;
      > div:last-child {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        i {
          font-size: 18px;
        }
        > span {
          i {
            font-size: 36px;
            font-weight: bold;
            color: $titleColor;
          }
        }
      }
    }
  }
  .l-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 16px;
    padding: 16px 0;
    > div:first-child {
      color: $gray;
      // width: calc(50% - 30px);
    }
    > div:nth-child(2) {
      font-size: 16px;
      font-weight: 400;
      color: $titleColor;
      // flex: 1;
      // padding-left: 15px;
    }
  }
  .l-step {
    text-align: center;
    color: $gray;
    font-size: 20px;
    > div:first-child {
      width: 60%;
      margin: 0 auto;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
    }
    > div:last-child {
      margin-top: 8px;
      font-size: 12px;
      font-weight: 400;
      color: $defocus;
    }
    .l-val {
      color: $themeColor;
      font-size: 24px;
      font-weight: 500;
    }
  }
  .l-img {
    width: 147px;
    margin: 0 auto;
  }
  .l-slider {
    width: 75%;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    color: $themeColor;
    > i {
      font-size: 20px;
    }
    > i:first-child {
      opacity: 0.3;
    }
    > div:nth-child(2) {
      width: 80%;
      margin: 0 5%;
    }
  }

  .l-func {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-self: center;
    > div {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      color: $gray;
    }
    i {
      font-size: 30px;
      margin: 0 15px;
      color: $gray;
    }
    > div.active {
      color: $themeColor;
      i {
        color: $themeColor;
      }
    }
  }
  > div:last-child {
    width: 40px;
    height: 40px;
    margin: 0 auto;
    margin-top: 20px;
    > i {
      font-size: 40px;
      color: $gray;
    }
    > i.active {
      color: $themeColor;
    }
  }
}
</style>