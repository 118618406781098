<template>
	<div class="light-index">
		<div>
			<div
				@click="
					$router.push({
						path: '/SmartClassroom/RoomList',
						query: { active: currentIndex },
					})
				"
				class="toggle-class"
			>
				<span>{{ roomInfo.name }}</span>
				<van-icon name="arrow-down" />
			</div>
			<div class="wheather-data">
				<div>
					<i class="iconfont icon-control03_wendu"></i>
					<span>温度 {{ roomInfo.temperature }}℃</span>
				</div>
				<div>
					<i class="iconfont icon-control03_shidu"></i>
					<span>湿度 {{ roomInfo.humidity }}%rh</span>
				</div>
				<div>
					<i class="iconfont icon-control_yezi"></i>
					<span>空气质量 {{ roomInfo.airQuality }}</span>
				</div>
			</div>
			<div class="swiper-toggle-page">
				<swiper
					ref="mySwiper"
					:options="swiperOptions"
					:auto-destroy="true"
					:delete-instance-on-destroy="true"
					:cleanup-styles-on-destroy="true"
					@ready="handleSwiperReadied"
					@click-slide="handleClickSlide"
					:autoUpdate="true"
				>
					<swiper-slide v-for="(item, i) in roomInfo.eqList" :key="i">
						<div
							class="toggle-equipment"
							:class="currentIndex === item.TerminalType ? 'active' : ''"
							@click="toggleEquipment(i)"
						>
							<i class="iconfont" :class="dataArr[item.TerminalType].icon"></i>
							<span>{{ dataArr[item.TerminalType].title }}</span>
						</div>
					</swiper-slide>
				</swiper>
			</div>
		</div>
		<div v-if="currentIndex === 1">
			<light :yourData="yourData"></light>
		</div>
		<div v-if="currentIndex === 2">
			<air-condition></air-condition>
		</div>
		<div v-if="currentIndex === 3">
			<curtains></curtains>
		</div>
		<div v-if="currentIndex === 7">
			<curtains></curtains>
		</div>
		<div v-if="currentIndex === 4">
			<lock></lock>
		</div>
		<div v-if="currentIndex === 5">
			<monitor></monitor>
		</div>
		<div v-if="currentIndex === 6">
			<radio-broadcast></radio-broadcast>
		</div>
		<div @click="showMenu = !showMenu" class="return-btn">
			<i class="iconfont icon-control04_menu"></i>
		</div>
		<van-popup
			v-model="showMenu"
			position="bottom"
			round
			class="return-menu"
			:style="{ height: '150px' }"
		>
			<van-grid clickable :column-num="3">
				<van-grid-item @click="$router.go(-1)">
					<div class="rm-item">
						<div><i class="iconfont icon-control_arrow"></i></div>
						<div>返回上一页</div>
					</div>
				</van-grid-item>
				<van-grid-item to="/SmartClassroom/Index">
					<div class="rm-item">
						<div><i class="iconfont icon-shouye_home01"></i></div>
						<div>设备首页</div>
					</div>
				</van-grid-item>
			</van-grid>
			<div @click="showMenu = false" class="popup-close">关闭</div>
		</van-popup>
	</div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import Light from './Light.vue'
import AirCondition from './AirCondition.vue'
import Curtains from './Curtains.vue'
import Lock from './Lock.vue'
import Monitor from './Monitor.vue'
import RadioBroadcast from './RadioBroadcast.vue'
import 'swiper/swiper-bundle.css'
import { Popup, Grid, GridItem } from 'vant'

import { getRoomEquipmentAll } from '@/api/TerminalEquipment/RoomEquipment.js'
import defineMethods from '@/methods'

export default {
	components: {
		Swiper,
		SwiperSlide,
		Light,
		AirCondition,
		Curtains,
		Lock,
		Monitor,
		RadioBroadcast,
		[Popup.name]: Popup,
		[Grid.name]: Grid,
		[GridItem.name]: GridItem,
	},
	directives: {
		swiper: directive,
	},
	computed: {
		swiper() {
			return this.$refs.mySwiper.$swiper
		},
	},
	data() {
		return {
			yourData: null,
			showMenu: false,
			chooseIndex: 0,
			currentIndex: 0, //所选设备类型
			swiperOptions: {
				slidesPerView: 3.1,
				slidesPerGroup: 3,
				spaceBetween: 0,
				observer: true,
			},
			//房间信息
			roomInfo: {
				id: 7,
				name: '',
				temperature: 20, //温度
				humidity: 30, //湿度
				airQuality: '良', //空气质量
				eqList: [],
			},
			dataArr: [
				{
					icon: '',
					title: '无',
					type: 0,
					// url: "/SmartClassroom/Equipement/Index",
				},
				{
					icon: 'icon-control_deng',
					title: '智能照明灯',
					type: 1,
					// url: "/SmartClassroom/Equipement/Light",
				},
				{
					icon: 'icon-control04_kongtiao',
					title: '空调',
					type: 2,
					// url: "/SmartClassroom/Equipement/AirCondition",
				},
				{
					icon: 'icon-control04_chuanglian',
					title: '窗帘',
					type: 3,
					// url: "/SmartClassroom/Equipement/Curtains",
				},
				{
					icon: 'icon-control04_door1',
					title: '自动门',
					type: 4,
					// url: "/SmartClassroom/Equipement/Lock",
				},
				{
					icon: 'icon-control04_jianceyi',
					title: '室内检测仪',
					type: 5,
					// url: "/SmartClassroom/Equipement/Monitor",
				},
				{
					icon: 'icon-control02_yinliang',
					title: '广播',
					type: 6,
					// url: "/SmartClassroom/Equipement/Monitor",
				},
				{
					icon: 'icon-control04_chuanglian',
					title: '窗帘',
					type: 7,
					// url: "/SmartClassroom/Equipement/Curtains",
				},
			],
		}
	},
	methods: {
		handleSwiperReadied() {},
		handleClickSlide() {},
		//切换设备
		toggleEquipment(i) {
			let eqInfo = this.roomInfo.eqList[i]
			this.currentIndex = eqInfo.TerminalType
			document.title = this.dataArr[this.currentIndex].title
			defineMethods.setCookie('TerminalList', JSON.stringify(eqInfo.TerminalList))
			defineMethods.setCookie('TerminalCmd', JSON.stringify(eqInfo.TerminalCmd))

			this.$router.replace({
				query: Object.assign(this.$route.query, {
					roomId: this.roomInfo.id,
				}),
			})
		},
		//加载房间设备
		async loadRoomEquipment(roomId) {
			localStorage.LightNum = ''
			localStorage.CurtainsNum = ''
			localStorage.LockState = ''

			var res = await getRoomEquipmentAll(roomId)
			this.roomInfo.eqList = res.Data

			res.Data.forEach((element, i) => {
				if (
					element.TerminalType - 1 == this.$route.query.active ||
					element.TerminalType == 7
				) {
					this.toggleEquipment(i)
					if (this.$route.query && this.$route.query.active >= 3) {
						this.swiper.slideTo(this.$route.query.active, 0, false)
					}
				}
			})
		},
	},
	mounted() {
		let info = JSON.parse(localStorage.RoomInfo)
		this.roomInfo.id = info.RoomId
		this.roomInfo.name = info.RoomNmae
		this.loadRoomEquipment(this.roomInfo.id)
	},
}
</script>
<style lang="scss" scoped>
@import '../../../style/public.scss';

.light-index {
	width: 100%;
	height: 100%;
	background: $bgColor;
	position: relative;
	> div:first-child {
		background-color: #fff;
		padding-bottom: 16px;
		margin-bottom: 20px;
	}
	.toggle-class {
		font-size: 16px;
		font-weight: bold;
		color: #2a2a2a;
		margin-bottom: 9px;
		margin-left: 24px;
		padding-top: 16px;
	}
	.wheather-data {
		display: flex;
		flex-flow: row nowrap;
		margin-left: 24px;
		margin-bottom: 16px;
		> div {
			position: relative;
			margin-right: 16px;

			font-size: 12px;
			font-weight: 400;
			color: #949494;
		}
		> div:not(:last-child):before {
			content: '';
			position: absolute;
			top: 50%;
			right: -8px;
			height: 10px;
			width: 1px;
			transform: translateY(-50%);
			background: $gray;
		}
	}
	.swiper-container {
		overflow: visible;
	}
	.swiper-toggle-page {
		margin-left: 16px;
	}
	.toggle-equipment.active {
		background: $themeColor;
		color: #fff;
	}
	.return-btn {
		position: fixed;
		top: 543px;
		right: 10px;
		width: 40px;
		text-align: center;
		height: 40px;
		line-height: 40px;
		background: #ffffff;
		box-shadow: 0px 0px 6px #c8c8c8;
		border-radius: 50%;
		color: #949494;
		i {
			font-size: 24px;
		}
	}
	.return-menu {
		.rm-item {
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: center;
			text-align: center;
			height: 80px;
			font-size: 12px;
			font-weight: 400;
			color: $titleColor;
			i {
				font-size: 28px;
			}
			> div:first-child {
				margin-bottom: 5px;
			}
		}
		margin-top: 20px;
		.popup-close {
			height: 38px;
			line-height: 38px;
			text-align: center;
			font-size: 17px;
			font-weight: 400;
			color: $titleColor;
			border-top: 1px solid #ebedf0;
		}
	}
	.toggle-equipment {
		width: 107px;
		height: 34px;
		line-height: 34px;
		text-align: center;
		box-shadow: 0px 0px 5px rgba(200, 200, 200, 0.5);
		border-radius: 8px;
		font-size: 14px;
		font-weight: 400;
		color: #2a2a2a;
		background: #fff;
		i {
			margin-right: 7px;
		}
	}
	.router-view-children {
		position: absolute;
		transition: all 0.5s;
		width: 100%;
		top: 148px;
		left: 0;
	}
}
</style>
<style lang="scss">
.light-index {
	.van-grid-item__content::after {
		border-width: 0;
	}
}
</style>
